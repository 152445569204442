import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

export const sourcesStore = defineStore('sources', () => {


	function getSource(id) {
		return sources.find((source) => source.id == id);
	}

	const sources = [
		{id: 0, show:true, user_add:true, slug:'ogn', short_name: 'OGN', name:'OGN', icon:'/images/source-icons/ogn-small.png'},
		{id: 1, show:true, user_add:true, slug:'spot-us', short_name: 'SPOT US', name:'SPOT', icon:'/images/source-icons/spot.png'},
		{id: 2, show:true, user_add:true, slug:'particle', short_name: 'Particle', name:'Particle Cell Tracker', icon:''},
		{id: 3, show:true, user_add:true, slug:'overland', short_name: 'Overland', name:'Overland App', icon:'/images/overland-icon.png'},
		{id: 4, show:true, user_add:true, slug:'spot-nz', short_name: 'SPOT NZ', name:'SPOT NZ', icon:'/images/source-icons/spot.png'},
		{id: 5, show:true, user_add:true, slug:'inreach-nz', short_name: 'InReach NZ', name:'InReach NZ', icon:'/images/source-icons/inreach.png'},
		{id: 6, show:true, user_add:true, slug:'btraced', short_name: 'BTraced', name:'BTraced App', icon:''},
		{id: 7, show:true, user_add:true, slug:'api', short_name: 'PureTrack API', name:'PureTrack API', icon:''},
		{id: 8, show:true, user_add:true, slug:'mt600-l-gnz', short_name: 'MT600-L-GNZ', name:'MT600-L from GNZ', icon:'/images/source-icons/mt600.png'},
		{id: 9, show:true, user_add:true, slug:'inreach-us', short_name: 'InReach', name:'InReach', icon:'/images/source-icons/inreach.png'},
		{id: 10, show:false, user_add:false,slug:'igc', short_name: 'IGC File', name:'IGC File', icon:''},
		{id: 11, show:false, user_add:true, slug:'pi', short_name: 'Pi Tracker', name:'Pi Tracker', icon:''},
		{id: 12, show:true, user_add:true, slug:'adsbhub', short_name: 'ADSBHub', name: 'ADSBHub', icon:'/images/source-icons/adsb.png'},
		{id: 13, show:true, user_add:true, slug:'igc-droid', short_name: 'IGCDroid', name:'IGCDroid', icon:'/images/igcdroid-icon.png'},
		{id: 14, show:false, user_add:true, slug:'seeyou-navigator', short_name: 'SeeYou', name:'SeeYou Navigator', icon:'/images/navigator-icon.png'},
		{id: 16, show:true, user_add:true, slug:'puretrack', short_name: 'PureTrack', name:'PureTrack iOS', icon:'/images/source-icons/puretrack.png'},
		{id: 17, show:true, user_add:true, slug:'teltonika', short_name: 'Teltonika', name:'Teltonika', icon:'/images/source-icons/teltonika.png'},
		{id: 18, show:true, user_add:true, slug:'cell-tracker', short_name: 'CellTracker', name:'Cell Tracker', icon:''},
		{id: 19, show:true, user_add:true, slug:'mt600', short_name: 'MT600', name:'MT600', icon:'/images/source-icons/mt600.png'},
		{id: 20, show:true, user_add:true, slug:'mt600-l', short_name: 'MT600-L', name:'MT600L', icon:'/images/source-icons/mt600.png'},
		{id: 21, show:true, user_add:true, slug:'api', short_name: 'api', name:'API', icon:''},
		{id: 22, show:false, user_add:true, slug:'fr24', short_name: 'FR24', name:'FlightRadar24', icon:''},
		{id: 23, show:true, user_add:true, slug:'xcontest', short_name: 'XContest', name:'XContest', icon:'/images/source-icons/xcontest.png'},
		{id: 24, show:true, user_add:true, slug:'skylines', short_name: 'SkyLines', name:'SkyLines', icon:'/images/source-icons/skylines.png'},
		{id: 25, show:true, user_add:true, slug:'flymaster', short_name: 'Flymaster', name:'Flymaster', icon:'/images/source-icons/flymaster-livec.png'},
		{id: 26, show:true, user_add:false, slug:'livegliding', short_name: 'LiveGliding', name:'LiveGliding', icon:''},
		{id: 27, show:true, user_add:false, slug:'adsbexchange', short_name: 'ADSBExchange', name:'ADSBExchange', icon:''},
		{id: 28, show:true, user_add:false, slug:'adsblol', short_name: 'adsb.lol', name:'adsb.lol', icon:'/images/source-icons/adsb.png'},
		{id: 29, show:false, user_add:false, slug:'adsbfi', short_name: 'adsb.fi', name:'adsb.fi', icon:'/images/source-icons/adsb.png'},
		{id: 30, show:false, user_add:true, slug:'sportstracklive', short_name: 'SportsTrackLive', name:'SportsTrackLive', icon:'/images/source-icons/sports-track-live-v2.png'},
		{id: 31, show:false, user_add:true, slug:'ffvl', short_name: 'FFVL', name:'FFVL Tracking', icon:'/images/source-icons/ffvl.png'},
		{id: 32, show:false, user_add:true, slug:'zoleo', short_name: 'Zoleo', name:'Zoleo', icon:'/images/source-icons/zoleo.png'},
		{id: 33, show:false, user_add:true, slug:'totalvario', short_name: 'Total Vario', name:'Total Vario', icon:'/images/source-icons/totalvario.png'},
		{id: 34, show:false, user_add:true, slug:'tracker', short_name: 'Tracker', name:'Tracker App', icon:'/images/source-icons/tracker.png'},
		{id: 35, show:false, user_add:true, slug:'ogn-icao', short_name: 'OGNICAO', name:'OGN ICAO', icon:'/images/source-icons/ogn-adsb.png'},
		{id: 36, show:false, user_add:true, slug:'xc-guide', short_name: 'XC Guide', name:'XC Guide', icon:'/images/source-icons/xc-guide.png'},
	];
 
	return { 
		sources,
		getSource
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(sourcesStore, import.meta.hot))
}

