import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed, watch } from 'vue';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { pointsStore } from "@/stores/PointsStore.js";
import { usePointsProcessor } from "@/Composables/UsePointsProcessor.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { computeHeight, computeSpeed } from '../Helpers.js';
import { coloursStore } from "@/stores/ColoursStore.js";

export const tracksStore = defineStore('tracks', () => {

	var tracks = {}; // main list of points, non reactive
	const tracksUpdated = ref(0);
	const tracksLoading = ref(0);

	const settings = settingsStore();
	const app = appStateStore();
	const points = pointsStore();
	const colours = coloursStore();
	const mapTools = useMapTools();

	const fromTimestamp = ref({}); // an array to keep track of the last track timestamp loaded for an item

	// get the bounds for all tracks
	function getBounds() {
		// console.log('getting bounds');
		let coordinates = [];

		for (const key in tracks) {
			for (var i=0; i<tracks[key].length; i++) {
				coordinates.push([tracks[key][i].long, tracks[key][i].lat]);
			}
		};

		// console.log('coordinates:');
		// console.log(coordinates);
		return mapTools.getBounds(coordinates);

	}

	function clearTracks() {
		tracks = {};
		fromTimestamp.value = {};
		tracksUpdated.value++;
	}


	function getFromTimestamp(key) {

		if (key in fromTimestamp.value) {
			return fromTimestamp.value[key];
		}
		return 0;
	}

	function setFromTimestamp(key, value) {

		if (!fromTimestamp.value[key]) fromTimestamp.value[key]=0; // set to zero if it doesn't exist yet

		if (fromTimestamp.value && value>fromTimestamp.value[key]) {
			fromTimestamp.value[key] = value; // update the last stamp if it's greater
		}
	}

	function removeTrackDataOfMissingKeys(keys) {
		//console.log('removeTrackDataOfMissingKeys called ');
		for (const [key, value] of Object.entries(tracks)) {
			if (!keys.includes(key)) {
				removeTrackData(key);
			}
		}
	}

	function addToTrackData(key, pointsArray) {
		if (key in tracks) {
			for (var i=0; i<pointsArray.length; i++)
			{
				tracks[key].push(pointsArray[i]);
			}
		} else {
			tracks[key] = pointsArray;
		}
		tracksUpdated.value++;
	}


	function removeTrackData(key) {
		if (key in tracks) {
			delete tracks[key];
		}
		if (key in fromTimestamp.value) {
			delete fromTimestamp.value[key];
		}
		tracksUpdated.value++;
	}


	function getTrackForUPlot(key, yAxis = 'altitude') {

		var chartData = [];

		if (key in tracks) {

			var timestamps = [];
			var altitudes = [];
			var gls = [];

			for (var i=0; i<tracks[key].length; i++) {
				timestamps.push(tracks[key][i].stamp);
				switch(yAxis) {
					case 'speed': altitudes.push(computeSpeed(tracks[key][i].speed, settings.speedUnits, 1)); break;
					default: 
						if (settings.useMovingAverage) {
							altitudes.push(computeHeight(tracks[key][i].alt_avg, settings.altitudeUnits)); 
						} else {
							altitudes.push(computeHeight(tracks[key][i].alt, settings.altitudeUnits)); 
						}

						break;
				}
				gls.push(tracks[key][i].gl ? computeHeight(tracks[key][i].gl, settings.altitudeUnits) : null);
			}

			chartData[0] = timestamps;
			chartData[1] = altitudes;
			if (yAxis=='altitude') chartData[2] = gls;
			else chartData[2] = new Array(timestamps.length).fill(null); // create an empty array the same length as the timestamps
		}

		return chartData;
	}

	// get ALL the points nearest a position
	// for FUTURE USE
	function getPointsNearestPosition(pos) {
		
		var nearestPoints = {}

		for (const key in tracks) {
			nearestPoints[key] = setHoverPointByPosition(key, pos);
		}
		return nearestPoints;
	}


	// get the point in the pos position
	function setHoverPointByPosition(key, pos) {

		// console.log('points.selected');
		// console.log(tracks);
		// console.log(points.selected);

		if (key in tracks) {
			if (points.selected) {
				// mergers the arrays together
				app.hoverPoint = Object.assign(points.selected, tracks[key][pos]);
			}
			
			// remove anything we don't want to use from previous, if it does'nt exist in the new one
			if (tracks[key][pos].receiver_name==null) app.hoverPoint.receiver_name=null;
			if (tracks[key][pos].tracker_uid==null) app.hoverPoint.tracker_uid=null;
			if (tracks[key][pos].thermal_climb_rate==null) app.hoverPoint.thermal_climb_rate=null;
		}
	}


	const geoJSON = function() {

		var features = [];
		//console.log(tracks);


		if (tracks!=null) {
			for (const key in tracks) {

				// find the colour of the point
				var point = points.getKey(key);

				// console.log("found point ");
				// console.log(colours.getColour(key, point?.colour));
				var coordinates = [];
				for (var i=0; i<tracks[key].length; i++) {
					coordinates.push([tracks[key][i].long, tracks[key][i].lat]);
				}

				features.push({
					'type': 'Feature',
					'properties': {
						'from': 0,
						'key': key,
						'colour': '#' + colours.getColour(key, point?.colour)
					},
					'geometry': {
						'type': 'LineString',
						'coordinates': coordinates
					}
				});

			}
		}


		return {
			"type": "FeatureCollection",
			"features": features
		};

	}

	return {
		tracks,
		tracksUpdated,
		getFromTimestamp,
		setFromTimestamp,
		addToTrackData,
		removeTrackData,
		removeTrackDataOfMissingKeys,
		geoJSON,
		getTrackForUPlot,
		setHoverPointByPosition,
		clearTracks,
		tracksLoading,
		getBounds,
	}

});


// make sure to pass the right store definition, `tracksStore` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(tracksStore, import.meta.hot))
}