import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed } from 'vue';
import { get_url_param } from '../Helpers.js';
import { messagesStore } from "@/stores/MessagesStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { tracksStore } from "@/stores/TracksStore.js";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);


export const appStateStore = defineStore('app', () => {
	const messages = messagesStore();
	const settings = settingsStore();
	const tracks = tracksStore();

	const loading = ref(0); // ensure we only have 1 load happening at once
	const firstLoadHappened = ref(false); // keep track if we've done our first load or not

	const selected = ref(null); // the currently selected target

	const selectedRangeStart = ref(null);
	const selectedRangeEnd = ref(null);

	const restartLoop = ref(false); // trigger a reload if set to true
	const selectedTracks = ref([]); // a list of which tracks to load

	const alerts = ref([]); // store any alerts for the selected item

	const sideBar = ref("legend");

	const searchingForTarget = ref(null); // used to identify if actively searching for a target

	const currentTime = ref(1); // used as a way to update anything

	// used to set the viewable area of the map
	const leftWidth = ref(0);
	const leftHeight = ref(0);
	const headerHeight = ref(0);
	const footerHeight = ref(0);
	const chartHeight = ref(0);

	const showInfoPanel = ref(false);
	const user = ref(null); // store the current logged in user

	const maxPointsForLabels = ref(400);
	const maxLegendPoints = ref(400);
	const maxTracks = ref(40);

	const skipNextLoad = ref(false); // used if we jump map to selected, we don't want to reload another time
	const mapMovedLoadLocations = ref(true); // only load locations if we've moved or zoomed the map

	// highlighting track position
	const currentHighlightTime = ref(null);
	const currentHighlightPos = ref(null);
	const hoverPoint = ref(null) // the point that is being hovered
	const hoveringOnChart = ref(false) // if we are on the chart or not, pause loading if so

	const paused = ref(false) // if we have paused loading live data for any reason
	const viewTrackStart = ref(null);
	const viewTrackEnd = ref(null);
	const selectedTrackStart = ref(null);
	const selectedTrackEnd = ref(null);



	const fromAppStore = ref(false);
	const style = ref(null); // the current style in use

	const groupSlug = ref(null); // the group slug if we are on such a page
	const groupLoaded = ref(false); // has the group been loaded yet or not
	const group = ref(null); // the current group

	const compSlug = ref(null); // the comp slug if we are on such a page
	const comp = ref(null); // the comp if it's loaded
	const compStats = ref(null);

	// const tasks = ref([]); // currently loaded comp tasks
	// const tasksToLoad = ref(null); // individual tasks to load
	// const reloadTasks = ref(0); // whether we should reload tasks or not
	// const redrawTasks = ref(0); // redraw the tasks, when hiding/showing
	
	const reloadComp = ref(false); // whether to reload the comp or not

	const thermals = ref(false); // the thermals if we've loaded them
	const selectedThermal = ref(false); // loaded thermal

	const stations = ref(false); // weather stations if we've loaded them
	const selectedStationClicked = ref(false); // ID number
	const selectedStation = ref(false); // the actual currently loaded weather station
	const reloadStations = ref(true);

	const imagesLoading = ref(0); // keep track of images that are asyncrhonously loading

	var windowWidth = ref(null);
	var windowHeight = ref(null);
	var version = ref(2);
	const redrawLabels = ref(0); // whether to redraw the labels

	const needToResetFrom = ref(false);
	const zoomToTrack = ref(false);

	const renderThermalHeights = true;
	const thermalHeightsMetaData = ref({});
	const colourRange = [
		'#303986',
		'#3D6EB3',
		'#58ADA3',
		'#60B45A',
		'#B0D457',
		'#F5E057',
		'#EBA743',
		'#BB6048',
		'#862B68',
		'#5F308C',
		'#472961',
		'#342440',
		'#221F20',
		'#414042',
		'#808285',
		'#BCBEC0',
		];

	// new layers style items
	const show = ref(null); // id of the main items to show e.g. 'main-menu', or null for nothing open.
	const showBack = ref([]); // queue of menu items to go back to
	const showLegend = ref('legend');

	const groupSearchText = ref('');



	// get the hashtag of the selected user, or given key
	const getHashTag = function(key=null) {

		if (selected.value==null && key==null) return false;

		// use one or the other.
		let keyString = selected.value;
		if (key!==null) keyString = key;

		if (keyString.substring(0,2)=='X-') {
			return '#' + keyString.substring(2);
		}
		return false;
	}


	const subscribed = computed(() => {
		if (!user.value) return false;
		if (user.value.subscribed) return true;
		return false;
	});
	const admin = computed(() => {
		if (!user.value) return false;
		if (user.value.level===1) return true;
		return false;
	});

	// function subscribed() {
	// 	console.log('checking user');
	// 	console.log(user);
	// 	if (!user) return false;
	// 	if (user.subscribed) return true;
	// 	return false;
	// };

	var map = null; // the main map object, made global here
	var mapLib = null; // the map library
	var mapScale = null; // the map scale

	// load the user data given by PHP
	user.value = JSON.parse(document.querySelector('meta[name="user"]').content);
	//Object.assign(user.value, userMeta); // keep it reactive


	// load the URL when we start this store
	loadUrl();


	function isMapDark() {
		if (style.value?.dark) return true;
		return false;
	}


	function deselectEverything() {
		clearTimeRange();
		selectedThermal.value = null;
		selectedStationClicked.value = null;
		selectedStation.value = null;
		selected.value = null;
		paused.value=false;

		// default to legend
		showLegend.value='legend';
	}

	// load any relevant URL items
	function loadUrl() {
		//console.log(window.location);
		// look for the new hash links
		if (window.location.hash) {
			let key = 'X-' + window.location.hash.substring(1);
			selected.value = key;
			searchingForTarget.value = key;
			addSelectedTrack(selected.value);
		}

		if (window.location.pathname.substring(0,2)=='/~')
		{
			let rego = window.location.pathname.substring(2).toUpperCase();
			selected.value = 'Y-' + rego;
			searchingForTarget.value = selected.value;
			addSelectedTrack(selected.value);
			window.history.replaceState({}, null, '/?k=Y-' + rego);
		}

		// aircraft
		if (get_url_param('a')) {
			selected.value = 'Y-' + get_url_param('a');
			searchingForTarget.value = selected.value;
			addSelectedTrack(selected.value);
		}

		// load selected item
		if (get_url_param('k')) {
			selected.value = get_url_param('k');
			searchingForTarget.value = get_url_param('k');
			addSelectedTrack(selected.value);
		}
	}


	function toggleSidebar(name) {
		if (sideBar.value==name) sideBar.value='legend';
		else sideBar.value = name;
	}

	// current time is used to trigger vue to update certain values regularly 
	setInterval(function () {
		//console.log('updating ticker')
		currentTime.value = Date.now()
	}, 2000)



	function checkForAppstore() {
		//console.log('checking for app store');

		if (get_url_param('fromappstore')) {
			if (Number(get_url_param('fromappstore'))==1) {
				window.localStorage.setItem('fromappstore', "true");
				//console.log('setting from app store=true');
				fromAppStore.value = true;
			} else {
				window.localStorage.removeItem('fromappstore');
				//console.log('removing from app store');
			}
		} else {
			if (window.localStorage.getItem('fromappstore')=='true') {
				//console.log('Found from app store in localstorage');
				fromAppStore.value = true;
			}
		}


		// console.log('fromAppStore');
		// console.log(fromAppStore.value);
	}

	function addSelectedTrack(item) {

		// console.log('selectedTracks.value.length');
		// console.log(selectedTracks.value.length);
		if (!subscribed.value) {
			if (selectedTracks.value.length>1) {
				messages.error('Upgrade to PureTrack Pro to select more than 2 tracks');
				return;
			}
		}

		if (!selectedTracks.value.includes(item)) {

			if (selectedTracks.value.length+1 <= maxTracks.value) {
				selectedTracks.value.push(item);
			} else {
				messages.error('Only ' + maxTracks.value + ' tracks can be opened at one time');
				return;
			}

		}

		// if (!selectedTracks.value.includes(item)) {
		// 	selectedTracks.value.push(item);
		// }
	}

	function removeSelectedTrack(item) {
		var index = selectedTracks.value.indexOf(item);
		if (index!==-1) {
			selectedTracks.value.splice(index, 1); // remove just the one found item
		}
	}


	function unpause() {
		if (needToResetFrom.value) {
			clearTimeRange();
			needToResetFrom.value=false;
		}
		hoveringOnChart.value = false;
		hoverPoint.value = null;
		restartLoop.value=true;
		viewTrackStart.value = null;
		viewTrackEnd.value = null;
		selectedTrackStart.value = null;
		selectedTrackEnd.value = null;
		paused.value = false;
	}



	function setMode(mode) {
		switch (mode) {
			case 'ga':
			case 'gliding':
			case 'marine':
			case 'freeflight':
			case 'all':
				settings.mode = mode;
				restartLoop.value=true;
				app.show=null;
				//console.log('setting mode to ' + settings.mode);
				break;
		}
	}

	function setMapScaleUnit() {

		switch (settings.distanceUnits)
		{
			case 'nauticalMiles':
				this.mapScale.setUnit('nautical');
				break;
			case 'miles':
				this.mapScale.setUnit('imperial');
				break;
			default:
				this.mapScale.setUnit('metric');
				break;
		}
	}

	function loadTimeRange(start, end, padding=0) {
		// console.log('loading time range ');
		// console.log(start);
		// console.log(end);

		tracks.clearTracks();

		hoverPoint.value = null;
		hoveringOnChart.value = false;
		
		viewTrackStart.value = dayjs.utc(start).subtract(padding, 'minute');

		if (end) {
			viewTrackEnd.value = dayjs.utc(end).add(padding, 'minute');
			selectedTrackEnd.value = dayjs.utc(end);
		} else { // now
			viewTrackEnd.value =dayjs.utc();
			selectedTrackEnd.value = dayjs.utc();
		}

		selectedTrackStart.value = dayjs.utc(start);

		restartLoop.value = true;
		paused.value = true;
		needToResetFrom.value = true;

		// console.log(viewTrackStart.value.toDate());
		// console.log(viewTrackEnd.value.toDate());
	}


	function clearTimeRange() {
		tracks.clearTracks();
		viewTrackStart.value= null;
		viewTrackEnd.value= null;
	}

	return { 
		selected,
		searchingForTarget,
		restartLoop,
		map,
		loadUrl,
		currentTime,
		leftWidth,
		headerHeight,
		footerHeight,
		chartHeight,
		showInfoPanel,
		maxPointsForLabels,
		maxLegendPoints,
		selectedTracks,
		maxTracks,
		skipNextLoad,
		addSelectedTrack,
		removeSelectedTrack,
		currentHighlightTime,
		currentHighlightPos,
		hoverPoint,
		subscribed,
		user,
		fromAppStore,
		checkForAppstore,
		alerts,
		admin,
		style,
		mapMovedLoadLocations,
		toggleSidebar,
		sideBar,
		groupSlug,
		groupLoaded,
		group,
		compSlug,
		comp,
		reloadComp,
		// tasks,
		thermals,
		stations,
		selectedStationClicked,
		selectedStation,
		reloadStations,
		deselectEverything,
		imagesLoading,
		selectedThermal,
		renderThermalHeights,
		colourRange,
		thermalHeightsMetaData,
		show,
		showBack,
		setMode,
		showLegend,
		windowWidth,
		windowHeight,
		loading,
		version,
		firstLoadHappened,
		unpause,
		hoveringOnChart,
		redrawLabels,
		groupSearchText,
		isMapDark,
		getHashTag,
		compStats,
		mapScale,
		setMapScaleUnit,
		viewTrackStart,
		viewTrackEnd,
		selectedTrackStart,
		selectedTrackEnd,
		loadTimeRange,
		paused,
		clearTimeRange,
		needToResetFrom,
		zoomToTrack,
	}

});


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(appStateStore, import.meta.hot))
}